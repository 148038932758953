@import '../../../../scss/main';

.user-registration {
  &-wrapper {
    @include flex-between-auto;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &.title-margin {
    margin-bottom: 10px;
  }

  .message {
    color: $color-primary;
    font-weight: 550;
    margin-bottom: 30px;
  }

  &-title {
    color: $color-primary;
    font-size: $font-16;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &-data {
    font-size: $font-14;
    color: $color-neutral-dark;
  }

  &-section {
    width: 46%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    & > button {
      @media screen and (max-width: 768px) {
        margin: 0 auto 20px auto;
      }
    }

    &-sub {
      @include flex-between-auto;

      & > div:nth-child(1) {
        margin-right: 19px;
      }
    }
  }

  &-elt {
    border: 1px dashed $color-neutral-dark;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;

    @media screen and (max-width: 900px) {
      padding: 10px;
    }

    li {
      @include flex-auto-center;
      font-size: $font-12;
      color: $color-neutral-dark;
      font-weight: 700;
      margin-top: 20px;

      path {
        fill: $color-primary;
      }

      span {
        margin-left: 10px;
      }
    }
  }

  &-lists {
    @include flex-between-auto;
  }

  &-list {
    &-title {
      color: $color-neutral-darker;
      font-size: $font-14;
      text-decoration: underline;
      font-weight: 700;
      margin-bottom: 15px;
    }

    &-item {
      color: $color-neutral-darker;
      font-weight: 400;
      margin: 0 0 10px 0;
    }
  }
}
