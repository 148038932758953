@import '../../scss/main';

.piecework {
  &-header {
    @include flex-between-center;
    flex-wrap: wrap;

    & > div {
      margin-bottom: 10px;
    }
  }

  &-select {
    font-style: normal;
    border-color: $color-primary;
    color: $color-primary;
    width: 200px;

    span {
      color: $color-primary;
    }
  }

  &-options {
    margin: 30px 0;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 800px) {
      @include flex-center-auto;
      float: none;
    }

    & > div {
      &:first-child {
        width: 180px;
      }
      margin: 5px 7px;
      width: 120px;
    }
  }

  &-info-box {
    @include basic-card-container;
    @include flex-between-center;
    background: $color-primary;
    flex-wrap: wrap;
    padding: 10px 20px;
    color: #fff;
    width: 100%;
    max-width: 446px;

    @media screen and (max-width: 800px) {
      margin: 30px 0;
    }

    &-elt {
      &:nth-child(2) {
        color: $color-secondary;
      }
    }

    &-nb {
      display: block;
      font-size: $font-18;
      font-weight: 700;
    }

    &-type {
      font-size: $font-12;
    }

    &-feedback {
      display: block;
      font-weight: 400;
      font-size: $font-10;
      margin-bottom: 5px;
    }

    &-form {
      & label {
        display: none;
      }

      & div {
        margin: 0;
        min-width: 133px;
        max-height: 30px;
      }
    }
  }
}
