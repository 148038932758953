@import '../../../scss/main';

.error-piecework {
  @include basic-card-container;
  position: fixed;
  left: 50%;
  top: 50vh;
  transform: translate(-50%, -50%);
  z-index: 11;

  padding: 35px;
  width: 100%;
  max-width: 674px;

  &-overlay {
    z-index: 5;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &-title {
    color: $color-primary;
    font-weight: 700;
    font-size: $font-18;
    margin-bottom: 30px;
    text-align: center;
  }

  & p {
    font-size: 1.1em;
    color: #000;
    margin-bottom: 30px;
    text-align: center;
  }

  &-btns {
    display: flex;
    justify-content: center;
    & button {
      width: 200px;
    }
  }
}
