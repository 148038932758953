@import '../../scss/main';

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;

  &-header {
    padding: 20px;
  }

  &-content {
    padding: 50px 10px;
    text-align: center;
    height: 100%;
    flex-grow: 1;
    background-color: $color-bg;
    background-position: bottom 0 center;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  &-title {
    font-size: $font-48;
    color: $color-primary;
  }

  &-subtitle {
    margin: 20px 0;
    font-size: $font-28;
  }

  &-link {
    display: block;
    font-size: $font-12;
    margin-bottom: 10px;
  }
}
