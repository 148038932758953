@import '../../../../scss/main';

.adLoadErrorContent {
  @include flex-column-start-center;
  padding: 80px 20px;
  color: $color-primary;

  h1 {
    margin-bottom: 40px;
  }
  p {
    font-size: $font-18;
    line-height: 24px;
  }

  button {
    margin-top: 40px;
    width: 200px;
  }
}
