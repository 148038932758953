@import '../../../scss/main';

.tabs {
  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 15px;
  }

  &-list {
    @include flex-between-center;
    text-align: center;

    &-elt {
      width: 100%;
      font-weight: 400;
      font-size: $font-16;
      color: $color-neutral-dark;
      border-bottom: 2px solid $color-neutral-light;
      padding: 0 0 15px 0;

      @media screen and (max-width: 1024px) {
        display: none;
      }

      &:hover {
        color: $color-primary;
        border-color: $color-primary;
        font-weight: 700;
        cursor: pointer;

        @media screen and (max-width: 1024px) {
          cursor: initial;
        }
      }

      &.active {
        color: $color-primary;
        border-color: $color-primary;
        font-weight: 700;

        @media screen and (max-width: 1024px) {
          display: block;
          text-align: left;
        }
      }
    }
  }

  &-arrow {
    @include flex-center-center;
    display: none;
    position: absolute;
    right: 15px;
    top: 5px;
    width: 36px;
    height: 36px;
    border-radius: 50%;

    &:hover {
      background-color: $color-neutral-light;
      cursor: pointer;
    }

    @media screen and (max-width: 1024px) {
      display: flex;
    }
  }

  &-menu {
    z-index: 20;
    position: absolute;
    width: 90%;
  }
}
