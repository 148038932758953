@import '../../../scss/main';

.password {
  display: flex;
  height: 100%;
  min-height: 100vh;

  &main {
    background-image: 'url(./images/bg-map.png)';
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }

  &-presentation {
    position: relative;
    background: $color-primary;
    max-width: 480px;
    height: 100%;
    min-height: 100vh;
    padding: 100px 47px 15px 80px;

    @media screen and (max-width: 1100px) {
      max-width: 300px;
      padding: 100px 40px 15px 40px;
    }

    @media screen and (max-width: 800px) {
      max-width: 100%;
      min-height: 200px;
      max-height: 250px;
      padding: 40px 20px;
    }

    svg {
      @media screen and (max-width: 800px) {
        display: block;
        margin: auto;
      }
    }
  }

  &-title {
    color: #fff;
    font-weight: 700;
    font-size: $font-48;
    margin: 100px 0 200px 0;

    @media screen and (max-width: 1100px) {
      font-size: $font-32;
    }

    @media screen and (max-width: 800px) {
      font-size: $font-24;
      margin: 26px 0 0 0;
      text-align: center;
    }
  }

  &-deco {
    @media screen and (max-width: 1100px) {
      width: 200px;
    }

    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  &-copyright {
    color: $color-neutral-dark;
    font-size: $font-10;
    font-weight: 400;
    display: block;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;

    @media screen and (max-width: 800px) {
      display: none;
    }

    &-link {
      color: $color-neutral-dark;
    }
  }

  &-content {
    flex-grow: 1;
    padding: 100px 20px;
    background-color: $color-bg;
    background-position: bottom 0 center;
    background-repeat: no-repeat;
    background-size: 100%;

    @media screen and (max-width: 800px) {
      padding: 25px;
    }

    &-elt {
      max-width: 660px;
      margin: auto;
    }
  }

  &-form-wrapper {
    @include basic-card-container;
    padding: 40px;
    margin-top: 20px;

    @media screen and (max-width: 600px) {
      padding: 20px;
    }
  }

  &-form {
    @include flex-column;

    & > div {
      margin-bottom: 10px;
    }

    button {
      width: 100%;
      text-align: center;
    }

    &-group {
      @include flex-between-auto;

      & > div {
        width: 49%;
      }
    }
  }

  &-info-text {
    display: block;
    width: 100%;
    text-align: center;
    color: $color-primary;
    font-size: $font-12;
    margin-top: 15px;

    &-link {
      color: $color-primary;
    }
  }

  &-mobile {
    &-actions {
      margin: 20px 0;
      display: none;

      @media screen and (max-width: 800px) {
        display: block;
      }

      button {
        margin: auto;
      }
    }

    &-question {
      text-align: center;
      display: block;
      width: 100%;
      margin-bottom: 3px;
      font-size: $font-12;
      color: $color-primary;
    }
  }

  &-header {
    @include flex-center-center;
    width: 100%;
    color: $color-primary;

    @media screen and (max-width: 800px) {
      flex-direction: column;
      text-align: center;
    }

    &-logo {
      @media screen and (max-width: 800px) {
        width: 42px;
        height: 42px;
      }
    }

    &-titles {
      margin: 0 45px 0 20px;

      @media screen and (max-width: 800px) {
        margin: 0;
      }
    }

    &-title {
      font-weight: 700;
      margin-bottom: 5px;
      font-size: $font-24;

      @media screen and (max-width: 800px) {
        margin-top: 5px;
      }
    }

    &-actions {
      @media screen and (max-width: 800px) {
        display: none;
      }
    }

    &-subtitle {
      font-size: $font-12;

      @media screen and (max-width: 800px) {
        display: block;
        max-width: 200px;
      }
    }
  }
}
