@import '../../../scss/main';

.user-account-tab {
  @include basic-card-container;
  font-size: $font-12;
  font-weight: 700;
  color: $color-neutral-dark;
  padding: 20px 30px;

  &-elt {
    padding: 10px 0;
    margin-bottom: 10px;

    &:hover {
      cursor: pointer;
      color: $color-primary;
    }
  }
}
