@import '../../../scss/main';

.user-details {
  &-form {
    @include flex-column;
  }

  &-elt {
    @include flex-between-auto;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &-form-group {
    @include flex-column;
    width: 42%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    & > div {
      margin-bottom: 20px;
      width: 100%;
    }

    &-sub {
      @include flex-between-auto;

      & > div {
        width: 48%;
      }
    }
  }

  &-btns {
    display: flex;
    margin-top: 50px;

    & > button {
      margin: 0;
    }

    & > button:nth-child(2) {
      margin-left: 16px;
    }
  }
}
