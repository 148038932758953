@import '../../../scss/main';

.loader {
  &-container {
    @include flex-center-center;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 20;
    height: 100vh;
    width: 100%;
    background-color: $color-neutral-dark-opacity;
  }

  &-modal {
    position: fixed;
    right: 0;
    top: 0;
    width: 95%;
    max-width: 840px;
    height: 100vh;
    z-index: 20;
    background-color: $color-neutral-dark-opacity;
    padding: 50px;
  }
}
