@import '../../../scss/main';

.imageWithTimeoutLoader {
  .loader {
    position: absolute;
    top: 50px;

    transform: 'translate(-50%, -50%)';
  }
}
