@import '../../../scss/main';

.faq {
  &-question {
    color: $color-primary;
    font-weight: 550;
  }

  &-answer {
    color: $color-primary;

    & > p {
      margin-bottom: 10px;
    }

    & ul + p {
      margin-top: 10px;
    }

    &.list-title {
      margin-bottom: 0;
    }
    & > ul {
      list-style: disc inside;
      margin-left: 20px;
    }
  }
}
