@import '../../../../scss/main';

.signInForm {
  .badCredentials {
    border-radius: 15px;
    padding: 10px;
    color: red;
    background-color: $color-neutral-light;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    p {
      padding-left: 20px;
    }
  }

  .form {
    @include flex-column;

    & > div {
      margin-bottom: 10px;
    }

    button {
      width: 100%;
      text-align: center;
    }

    .group {
      @include flex-between-auto;

      & > div {
        width: 49%;
      }
    }
  }
}
