@import '../../../scss/main';

.user-statistics {
  &-header {
    @include flex-between-center;
    flex-wrap: wrap;
    margin-bottom: 60px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &-title {
    margin: 0;
  }

  &-form {
    @include flex-between-center;

    @media screen and (max-width: 768px) {
      @include flex-column-center-center;
    }

    & > div {
      width: 150px;

      @media screen and (max-width: 768px) {
        width: 230px;
      }
    }

    & > div:nth-child(1) {
      margin-right: 30px;

      @media screen and (max-width: 768px) {
        margin: 0 0 10px 0;
      }
    }
  }

  &-table {
    width: 100%;

    .user-column {
      width: 170px;
    }

    &-wrapper {
      overflow-x: auto;
      width: 100%;
    }

    text-align: center;
    border-collapse: collapse;

    tr {
      border-radius: 60px;
    }

    th {
      color: $color-neutral-dark;
      font-weight: 400;
      font-size: $font-14;
      padding: 10px;
      border-bottom: 1px solid $color-neutral-light;
    }

    td {
      @include font-btn;
      font-size: $font-14;
      color: $color-primary;
    }

    tr:nth-child(even) {
      background: $color-neutral-light;
    }

    th:nth-child(1),
    td:nth-child(1) {
      text-align: left;
      padding: 10px 15px;
    }
  }
}
