@import '../../../scss/main';

.logo-button {
  @include flex-center-center;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: none;
  width: 36px;
  height: 36px;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    &:hover {
      cursor: default;
    }
  }
}
