@import '../../../scss/main';

.ad-basic-details {
  &-view {
    display: block;
    width: 100%;
    height: auto;
  }

  &-description {
    @include flex-between-center;
    margin: 35px 0 0 0;
    align-items: flex-start;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin: 15px 0 0 0;
      align-items: flex-start;
    }

    &-elt {
      width: 60%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      &-item {
        padding: 20px 50px 20px 0;
        border-bottom: 1px solid $color-neutral-dark;

        @media screen and (max-width: 768px) {
          padding: 20px;
        }

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border: none;
        }
      }

      h3 {
        font-weight: 700;
        color: $color-primary;
        font-size: $font-18;
        margin-bottom: 10px;
      }

      p,
      span {
        font-size: $font-12;
        color: $color-neutral-darker;
        line-height: 19px;
      }

      span {
        display: block;
      }
    }

    &-data {
      @media screen and (max-width: 768px) {
        margin-top: 20px;
      }

      &-item {
        @include flex-auto-center;
        font-size: $font-14;
        color: $color-primary;
        margin-bottom: 20px;
      }

      svg {
        width: 15px;
        height: auto;
        margin-right: 7px;
      }

      span {
        @include font-content;
      }
    }
  }

  &-link {
    color: $color-primary;
  }

  &-seller {
    @include flex-auto-center;

    & > svg {
      margin-right: 7px;
    }
  }
}
