@import './../../scss/variables';
@import './../../scss/mixins';

.body {
  height: auto;
  min-height: 100vh;

  &-content {
    @include flex-center-auto;
    width: 100%;
    max-width: 1440px;
    margin: 30px auto 0 auto;
    padding: 30px;
    background: $color-bg;
  }
}

.main-content {
  flex-grow: 1;
}
