@import '../../../../scss/main';

.user-registration {
  .geographic-sectors-list {
    max-height: 500px;
    overflow-y: auto;
    li {
      @include flex-auto-center;
      font-size: $font-12;
      color: $color-neutral-dark;
      font-weight: 700;
      margin-top: 10px;

      path {
        fill: $color-primary;
      }

      span {
        margin-left: 10px;
      }
    }
  }
  color: $color-neutral-darker;
  font-weight: 400;
}
