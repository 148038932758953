@import '../../scss/main';

.alerts-table {
  border-collapse: collapse;
  text-align: left;
  font-size: $font-12;
  margin: 0 20px 20px 0;
  width: 100%;

  tbody tr {
    cursor: pointer;
  }
  @media screen and (max-width: 610px) {
    margin-right: 0;
  }

  &-desktop {
    @media screen and (max-width: 610px) {
      display: none;
    }
  }

  &-mobile-date {
    display: none;

    @media screen and (max-width: 610px) {
      display: block;
    }
  }

  &-content {
    @include flex-auto-center;

    & > div {
      margin-left: 11px;
    }
  }

  thead {
    color: $color-neutral-dark;
    font-weight: 700;
  }

  th {
    padding: 20px 28px;
  }

  tbody {
    color: $color-neutral-darker;
  }

  td {
    border-top: 1px solid $color-border;
    padding: 20px 28px;

    img {
      width: 36px;
      height: 36px;
    }
  }

  &-btns {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      background-color: transparent;
    }

    &-calendar {
      svg {
        width: 25px;
        height: 25px;
        color: blue;
      }
    }

    &-garbage {
      svg {
        width: 23px;
        height: 23px;
        color: red !important;
      }
    }

    text-align: right;
  }

  &-btn {
    width: 24px;
    height: 24px;
    background-position: center;
    border: none;
    background-color: #fff;

    &:hover {
      cursor: pointer;
    }
  }
}
