@import '../../../scss/main';

.signIn {
  @include basic-card-container;
  padding: 40px;
  margin-top: 20px;
  width: 100%;

  .infoText {
    display: block;
    width: 100%;
    text-align: center;
    color: $color-primary;
    font-size: $font-12;
    margin-top: 15px;
    .link {
      color: $color-primary;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 20px;
  }
}
