@import '../../scss/main';

.maintenance {
  display: flex;
  height: 100%;
  min-height: 100vh;

  main {
    background-image: 'url(./images/bg-map.png)';
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }

  &-content {
    flex-grow: 1;
    padding: 100px 20px;
    background-color: $color-bg;
    background-position: bottom 0 center;
    background-repeat: no-repeat;
    background-size: 100%;

    @media screen and (max-width: 800px) {
      padding: 25px;
    }

    &-title {
      color: $color-primary;

      margin: 100px 0 200px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h1 {
        font-weight: 700;
        margin-bottom: 40px;
      }

      p {
        font-size: 20px;
        margin-bottom: 10px;

        span {
          font-weight: 700;
        }
      }
      @media screen and (max-width: 800px) {
        margin: 26px 0 0 0;
        text-align: center;
      }
    }
  }
}
