@import '../../../scss/main';

.ad-details-geo {
  &-map {
    height: 300px;
    width: 100%;
    border-radius: 15px;
  }

  & .button {
    width: 100%;
    margin-top: 10px;
    border-radius: 5px;
  }

  &-single-parcel-container {
    &-elt {
      @include basic-card-container;
      display: flex;
      margin: 5px 0;
      padding: 10px;

      .MuiTypography-body1 {
        font-size: $font-14;
      }
      .MuiFormControlLabel-root {
        height: 20px;
      }

      &-map {
        width: 250px;
        height: 150px;
        border-radius: 15px;
      }

      &-infos {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        color: $color-primary;
        font-size: $font-14;
        flex-grow: 1;
        padding: 10px 0 10px 20px;

        & p {
          display: flex;

          & span {
            font-weight: 700;
            padding-left: 15px;
          }

          & a {
            color: $color-primary;
            font-size: $font-14;
            padding-left: 5px;
          }
        }
      }
    }
  }
}
