@import '../../../scss/main';

.section-title {
  border-left: 2px solid $color-primary;
  padding-left: 9px;

  &.noBorder {
    border: none;
  }

  &-main {
    color: $color-primary;
    font-weight: 400;
    font-size: $font-24;
  }

  &-sub {
    color: $color-neutral-dark;
    font-size: $font-12;
    display: block;
    margin-top: 2px;

    a {
      text-decoration: none;
      color: $color-primary;
    }

    &-elt {
      color: $color-primary;
    }
  }
}
