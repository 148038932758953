@import '../../../../scss/main';

.user-team {
  &-header {
    @include flex-between-center;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    &-title {
      margin: 0;
    }

    button {
      margin-bottom: 40px;
    }
  }

  &-content {
    @include flex-center-auto;
    flex-wrap: wrap;

    & > div {
      @include flex-column-between-auto;
      margin: 0 20px 50px 20px;
    }
  }
}
