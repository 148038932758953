@import '../../../scss/main';

.chose-folder {
  @include basic-card-container;
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translate(-50%);
  z-index: 11;
  width: 100%;
  max-width: 588px;
  padding: 35px;

  .loader-margin {
    margin: 20px 0;
  }

  &-header {
    @include flex-between-center;
  }

  &-title {
    @include flex-auto-center;

    span {
      color: $color-primary;
      font-size: $font-18;
      font-weight: 700;
      margin-left: 14px;
    }
  }

  &-content {
    margin-top: 60px;
  }

  &-line {
    @include flex-auto-center;
    border-bottom: 1px solid $color-row;

    &.header-line span {
      color: $color-primary;
    }

    & > span {
      font-size: $font-14;
      font-weight: 700;
      margin-left: 14px;
      padding: 10px 0;
      color: $color-neutral-darker;
    }

    &:last-of-type {
      border: none;

      & > span {
        padding-bottom: 0;
      }
    }

    &.sub {
      padding-left: 50px;
    }

    & > svg {
      margin-left: 15px;
    }
  }

  &-footer {
    @include flex-between-center;
    margin-top: 30px;
  }
}
