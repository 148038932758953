@import '../../scss/main';

.market-studies-create {
  width: 100%;

  &-header {
    @include flex-between-center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &-form {
    @include basic-card-container;
    padding: 23px;
    margin-top: 40px;

    &-inputs {
      display: flex;
      flex-wrap: wrap;
    }

    &-group {
      width: 100%;
      min-width: 300px;
      max-width: 375px;
      margin-right: 15px;

      & > div {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    &-btns {
      margin-top: 40px;

      button {
        display: inline-block;

        &:nth-child(1) {
          margin-right: 13px;
        }
      }
    }
  }
}
