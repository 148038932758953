@import '../../scss/main';

.comparative-studies-form {
  @include basic-card-container;
  padding: 25px 45px;
  margin: 35px 0 0 0;

  @media screen and (max-width: 1024px) {
    padding: 20px;
  }

  &-wrapper {
    width: 100%;
    max-width: 1024px;
  }

  &-group {
    @include flex-between-auto;
    width: 100%;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  }

  &-sub {
    width: 49%;

    @media screen and (max-width: 1200px) {
      width: 100%;
    }

    & > div {
      width: 100%;
      margin-bottom: 20px;
    }

    &-address {
      @include flex-between-auto;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }

      & > div:nth-child(1) {
        width: 49%;

        @media screen and (max-width: 1024px) {
          width: 100%;
          margin-bottom: 20px;
        }
      }

      & > div:nth-child(2) {
        width: 38%;

        @media screen and (max-width: 1024px) {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }

    &-surface {
      @include flex-between-auto;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }

      & > div {
        width: 49%;

        @media screen and (max-width: 1024px) {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }

  &-btns {
    @include flex-center-auto;

    & > button {
      margin: 0 9px;
    }
  }
}
