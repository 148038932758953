@import '../../scss/main';

.loader-circular {
  @include flex-center-center;
  width: 100%;

  &-user {
    @include flex-column-center-center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 50;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    z-index: 100;

    & p {
      color: $color-primary;
      font-size: 1.5em;
      margin-top: 40px;
    }
  }
}
