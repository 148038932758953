@import '../../scss/main';

.window-modal-container-content {
  p {
    width: 100%;
  }
  p:first-child {
    margin-bottom: 10px;
  }

  p > span {
    font-weight: 700;
  }
}
