@import '../../../../../scss/main';

.ad-thumb {
  @include basic-card-container;
  @include flex-column-between-auto;
  width: 260px;
  padding: 14px 17px;
  position: relative;
  transition: box-shadow 0.4s;

  @media screen and (max-width: 375px) {
    width: 100%;
  }

  &-top {
    @include flex-between-center;
    margin-bottom: 10px;

    & > span {
      width: 74px;
    }

    &-status {
      @include flex-center-center;
      font-size: $font-12;
      font-weight: 700;

      &-on,
      &-off {
        @include flex-center-center;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        border: 4px solid #fff;
        margin-right: 6px;
      }

      &-on {
        background: $color-comparative-green;
        border-color: $color-like-opacity;

        &-text {
          color: $color-comparative-green;
        }
      }

      &-off {
        background: $color-ui-notif;
        border-color: $color-ui-notif-opacity;

        &-text {
          color: $color-ui-notif;
        }
      }
    }
  }

  &-check {
    @include flex-center-center;
    height: 24px;
    width: 24px;
    background-color: #fff;
    left: 27px;
    border-radius: 50%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }

  &-cover {
    margin-bottom: 5px;
    height: 226px;
    position: relative;

    & > span {
      position: absolute;
      bottom: 15px;
      left: 13px;
    }

    &-img {
      width: 226px;
      height: 230px;

      object-fit: contain;

      @media screen and (max-width: 375px) {
        width: 100%;
      }
    }
  }

  &-main-section {
    &:hover {
      cursor: pointer;
    }
  }

  &-header {
    font-weight: 700;
    margin-bottom: 10px;

    &-elt {
      @include flex-between-center;
      color: $color-primary;
      width: 100%;
      font-size: $font-16;
    }

    &-title {
      font-size: $font-16;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-price {
      font-size: $font-14;
      margin-left: 10px;
    }

    &-city {
      @include text-ellipsis;
      display: block;
      color: $color-neutral-darker;
      font-size: $font-14;
    }

    &-meter-price {
      font-size: $font-12;
      font-weight: 400;
      color: $color-neutral-darker;
    }
  }

  &-infos {
    color: $color-neutral-darker;
    font-weight: 700;
    font-size: $font-14;
    margin: 10px 0 15px 0;

    &-item {
      @include text-ellipsis;
      margin: 4px 0;
    }
  }

  &-footer {
    @include flex-between-center;
    &-elt {
      @include flex-between-center;
    }

    &-author {
      @include text-ellipsis;
      display: block;
      margin-bottom: 5px;
      font-size: $font-12;
      color: $color-neutral-darker;
      text-decoration: none;

      a {
        font-style: normal;
        text-decoration: underline;
      }
    }

    &-badges {
      @include flex-center-center;
      margin-right: 14px;

      & > div {
        margin-right: 7px;
      }
    }

    &-pictos {
      display: flex;
      position: relative;
    }

    &-icons {
      display: flex;
      align-items: center;
      position: relative;
      & > * {
        margin-right: 5px;
      }
    }

    &-picto {
      @include flex-center-center;
      width: 39px;
      height: 39px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }
  }

  &-prospec-icon {
    position: relative;
    right: -4px;
  }

  &-like {
    @include flex-center-center;
    background-color: $color-like;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
}
