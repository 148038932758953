@import '../../scss/main';

.graph-container {
  margin: 20px 0;

  &-line {
    height: 300px;

    @media screen and (min-width: 800px) {
      margin: 20px 0;
      height: 477px;
    }
  }
}

#chartjs-tooltip {
  line-height: 1.5rem;
}

.tooltip-body {
  position: relative;
  border: 5px solid $color-primary;
  padding: 2px 10px;
  background-color: $color-primary;
  width: auto;
  margin: 16px auto;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
}

.tooltip-body::before {
  content: '';
  position: absolute;
  display: block;
  left: 50%;
  bottom: 0;
  border: 10px solid transparent;
  border-bottom: 0;
  border-top: 10px solid $color-primary;
  transform: translate(-50%, calc(100% + 5px));
}

.tooltip-title {
  background-color: #fff;
  color: $color-neutral-darker;
}
