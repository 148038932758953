@import '../../../scss/main';

.action-card {
  @include basic-card-container;
  text-decoration: none;
  transition: box-shadow 0.4s;
  display: block;

  &:hover {
    @include box-hover;
  }

  &-content {
    @include flex-column-between-center;
    width: 100%;
    height: 100%;
    padding: 33px 10px 20px 10px;
  }

  &-content-add {
    @include flex-column-center-center;
    width: 100%;
    height: 100%;
    padding: 33px 10px 20px 10px;
  }

  &-elts {
    @include flex-column-center-center;

    & > svg {
      margin-bottom: 7px;
    }
  }

  &-title {
    font-weight: 700;
    color: $color-primary;
    font-size: $font-18;
    text-align: center;
  }

  &-infos,
  &-text {
    color: $color-neutral-dark;
    font-size: $font-12;
    margin-top: 5px;
    text-align: center;
  }

  &-update-nb {
    @include flex-center-center;
    background: $color-ui-notif;
    color: #fff;
    font-weight: 500;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: $font-12;
  }

  &-add {
    @include flex-center-center;
    width: 56px;
    height: 56px;
    background: $color-neutral-light;
    border-radius: 28px;
    border: 1px dashed $color-neutral-dark;
    margin-bottom: 17px;
  }
}
