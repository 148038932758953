@import '../../../../scss/main';

.active-search-card {
  @include basic-card-container;
  @include flex-column-between-auto;
  padding: 13px;
  width: 320px;

  &-header {
    @include flex-between-center;
    background: $color-neutral-light;
    border-radius: 15px;
    padding: 14px;
  }

  &-title {
    @include text-ellipsis;
    max-width: 230px;
    font-size: $font-18;
    font-weight: 700;
    color: $color-primary;
  }

  &-subtitle {
    font-size: $font-10;
    color: $color-neutral-dark;
  }

  &-updates {
    @include flex-center-center;
    background: $color-ui-notif;
    color: #fff;
    font-size: $font-12;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  &-content {
    margin-left: 10px;
  }

  &-cities {
    @include flex-auto-center;
    font-weight: 700;
    font-size: $font-14;
    color: $color-primary;

    &-list {
      @include text-ellipsis;
      margin-left: 7px;
      height: 51px;
      width: 259px;
    }
  }

  &-types {
    @include text-ellipsis;
    display: block;
    font-size: $font-14;
    color: $color-neutral-darker;
    margin-left: 25px;
  }

  &-footer {
    @include flex-between-center;

    &-elt {
      & > label {
        margin: 0;
      }
    }

    &-btns {
      @include flex-center-center;

      & > button {
        margin-left: 9px;
      }
    }
  }
}
