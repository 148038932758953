@import '../../../scss/main';

.comparative-studies-details {
  padding: 0px;
  height: 100%;
  &-section {
    height: 100%;

    &-message {
      text-align: center;
      font-style: italic;
      font-size: 0.9em;
      margin-bottom: 5px;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0;
  }

  &-title {
    color: $color-primary;
    font-weight: 500;
    font-size: $font-32;
    @include font-content;
  }

  &-header-postal {
    font-size: $font-18;
  }

  &-header-elt {
    @include flex-between-center;
    margin: 0 0 40px 0;

    button {
      width: 36px;
      height: 36px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0px 0px 4.03846px rgba(0, 0, 0, 0.1);
      border: none;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &-header-infos {
    position: relative;
    margin-bottom: 5px;
  }

  &-primary-info {
    color: $color-neutral-darker;
    font-weight: 700;
    @include font-content;
    font-size: $font-18;
  }

  &-secondary-info {
    color: $color-neutral-darker;
    font-weight: 500;
    @include font-content;
    font-size: $font-16;
    position: absolute;
    left: 200px;
  }

  &-badges {
    @include flex-between-auto;
    margin: 30px 0 50px 0;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin: 30px 0;
    }
  }

  &-badge {
    @include flex-auto-center;
    border-radius: 10px;
    border: 1px dashed $color-neutral-dark;
    background: #fff;
    padding: 5px 20px;
    text-align: center;

    @media screen and (max-width: 768px) {
      margin: 10px 0;
    }

    &.comparative-studies-details-badge-market-price {
      flex-direction: column;
    }

    &-price-amount {
      @include flex-auto-center;
      display: block;
      font-weight: 700;
      font-size: $font-24;

      &.less {
        color: $color-comparative-green;
      }

      &.more {
        color: $color-ui-notif;
      }

      & > svg {
        margin-left: 7px;
      }
    }

    &-text {
      font-weight: 700;
      color: $color-primary;
      margin-right: 10px;
    }

    &-price {
      background: $color-primary;
      color: #fff;
      font-weight: 500;
      padding: 5px 10px;
      border-radius: 60px;
      margin-left: 8px;
    }
  }

  &-tabs {
    @include flex-center-auto;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    margin-bottom: 30px;
  }

  &-tab {
    width: 33%;
    background: #fff;
    padding: 15px;
    text-align: center;
    border-bottom: 2px solid #fff;
    color: $color-neutral-darker;
    font-weight: 700;
    @include font-content;
    font-size: $font-16;

    &:hover {
      cursor: pointer;
    }

    &.active {
      border-color: $color-badge-new;
    }

    &-amount {
      color: $color-badge-new;
    }
  }

  &-table {
    &-wrapper {
      height: 50%;
      overflow: auto;

      & .no-content {
        text-align: center;
        font-size: 1.5em;
        margin-top: 40px;
      }
    }

    width: 100%;
    text-align: center;
    border-collapse: separate;
    border-spacing: 0 10px;

    td {
      background: #fff;
      box-shadow: inset 0px -1px 0px #e0e0e0;
      color: $color-primary;
    }

    td:nth-child(1),
    td:nth-child(2) {
      background: none;
      box-shadow: none;
    }

    th,
    td {
      font-size: $font-14;
      padding: 0 5px;
    }
  }
}
