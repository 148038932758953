@import '../../../scss/main';

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
}

.window-modal {
  @include basic-card-container;
  position: fixed;
  left: 50%;
  top: 50vh;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: auto;
  max-width: 600px;
  padding: 35px;
  text-align: center;

  &-container {
    display: flex;
    align-items: center;

    &-icon {
      width: 20%;
      img {
        width: 50px;
      }
    }

    &-content {
      flex: 1;
      text-align: left;

      .centered-email {
        text-align: center;
      }
    }
  }

  &-footer {
    @include flex-center-center;
    margin-top: 30px;
  }

  h3 {
    margin-bottom: 20px;
  }

  button {
    width: 150px;

    &:first-child {
      margin-right: 20px;
    }
  }
}
