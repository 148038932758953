@import '../../../scss/main';

.signActionTitle {
  display: flex;
  color: $color-primary;

  .logo {
    width: 56px;
    height: 56px;

    @media screen and (max-width: 800px) {
      width: 42px;
      height: 42px;
    }
  }

  .titles {
    margin: 0 10px 0 20px;
    flex-grow: 1;

    .title {
      font-weight: 700;
      margin-bottom: 5px;
      font-size: $font-24;

      @media screen and (max-width: 800px) {
        margin-top: 5px;
      }
    }

    .subtitle {
      font-size: $font-12;

      @media screen and (max-width: 800px) {
        display: block;
        max-width: 200px;
      }
    }

    @media screen and (max-width: 800px) {
      margin: 0;
    }

    @media screen and (max-width: 800px) {
      text-align: center;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
}
