@import '../../../scss/main';

.confirmation {
  @include basic-card-container;
  position: relative;
  top: 100px;
  margin: auto;
  padding: 35px;
  width: 100%;
  max-width: 674px;

  &-overlay {
    z-index: 5;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &-title {
    color: $color-primary;
    font-weight: 700;
    font-size: $font-18;
    margin-bottom: 30px;
  }

  &-btns {
    button {
      display: inline-block;

      &:nth-child(1) {
        margin: 0 13px 0 0;
      }
    }
  }
}
