@import '../../../scss/main';

.action-notification {
  &-container {
    @include flex-auto-center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: $color-primary;
    color: #fff;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 25px;
  }

  &-content {
    margin-left: 20px;
    flex-grow: 1;
  }

  &-success {
    background: $color-like;
    color: $color-like-text;

    & > button {
      color: $color-like-text;
    }
  }

  &-failure {
    background: $color-ui-notif;
  }
}
