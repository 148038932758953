@import '../../scss/main';

.alert-modal {
  @include flex-between-center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: $color-primary;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 25px;
  color: white;
  &-elt {
    @include flex-between-center;
  }
  &-text {
    padding: 0 30px;
    h3 {
      margin-bottom: 10px;
    }

    p {
      font-size: $font-14;
      line-height: 20px;
    }
  }

  &-btn {
    @include flex-center-center;
    border: none;
    background: none;
    @include font-btn;
    font-weight: 500;
    color: #fff;
    font-size: $font-14;
    line-height: 24px;

    svg {
      margin: 0 0 2px 12px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
