@import '../../../../scss/main';

.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 0;
    margin-right: 10px;
  }
}
