@import '../../scss/main';

.my-folders-ad-creation {
  width: 100%;
  max-width: 1024px;

  &-header {
    @include flex-between-center;
  }

  &-span {
    color: $color-primary;
  }

  &-picto {
    width: 39px;
    height: 39px;
    background-color: #fff;
    border-radius: 20%;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    margin: 5px;
    @include flex-center-center;

    &:hover {
      cursor: pointer;
    }
  }

  &-group {
    display: flex;

    @media screen and (max-width: 768px) {
      display: none;
    }
    &-btn {
      margin-left: 20px;
    }
  }

  &-content {
    @include flex-between-auto;
    margin-top: 40px;

    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
    }
    &-main {
      @include flex-column;
      width: 100%;
      max-width: 675px;
      margin-right: 20px;

      h4 {
        font-size: $font-18;
        color: $color-primary;
        margin-bottom: 10px;
      }

      &-detail {
        display: flex;
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        border-radius: 15px;
        padding: 20px;
        margin-bottom: 40px;
      }

      &-group {
        @include flex-column;

        &-title {
          border-left: 2px solid $color-primary;
          padding-left: 8px;
          color: $color-primary;
          font-weight: 400;
          font-size: $font-28;
        }

        &-form {
          @include basic-card-container;
          @include flex-column;
          padding: 20px;
          margin-top: 20px;

          .location-form-buttons {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
              margin: 0 10px;
            }

            &.urbanease-btn-hidden {
              justify-content: flex-end;
            }
          }

          &-row {
            @include flex-auto-center;
            color: $color-neutral-dark;

            & > div {
              flex-grow: 1;
              margin-right: 10px;
              margin-bottom: 10px;
            }
          }

          &-btn {
            button {
              margin-right: 10px;
              margin-top: 20px;
              color: #1cd4fd;
              float: right;
            }
          }
        }
      }
    }

    &-side {
      @include flex-column;
      width: 100%;
      max-width: 330px;

      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
      h4 {
        color: $color-primary;
        font-size: $font-14;
        margin-bottom: 10px;
      }

      &-folder {
        background: $color-neutral-light;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        border-radius: 15px;
        padding: 20px;
        margin-bottom: 24px;

        & > div {
          margin-bottom: 10px;
        }

        div {
          width: 100%;
        }
      }

      &-business {
        @include basic-card-container;
        padding: 20px;
        margin-bottom: 24px;

        div {
          width: 100%;
        }

        & > div {
          margin-bottom: 10px;
        }

        button {
          width: 100%;
          margin-top: 7px;
        }
      }

      &-recent {
        @include basic-card-container;
        padding: 20px;
        padding-bottom: 40px;
        margin-bottom: 20px;
        color: $color-neutral-dark;
      }
    }
  }

  &-side {
    &-line {
      font-weight: 700;
      font-size: $font-12;

      &-data {
        @include flex-between-center;
        padding: 8px 0;

        &:hover {
          .my-folders-ad-creation-side-line-delete > button {
            display: block;
          }
        }
      }

      &-name {
        width: 60px;
        margin-right: 10px;
      }

      &-picto {
        margin-right: 15px;
      }

      &-date {
        width: 70px;
        margin-right: 10px;
      }

      &-delete {
        margin-left: 20px;
        width: 36px;
        height: 36px;
        & > button {
          display: none;
        }
      }

      &-deco {
        width: 1px;
        height: 19px;
        background: $color-neutral-dark;
        margin-left: 100px;

        @media screen and (max-width: 768px) {
          margin-left: 28%;
        }
      }
    }
  }
}
