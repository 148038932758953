@import '../../scss/main';

.comparative-studies {
  flex-grow: 1;

  &-main-header {
    @include flex-between-center;

    @media screen and (max-width: 600px) {
      flex-direction: column;

      & > div:nth-child(1) {
        margin-bottom: 20px;
      }
    }
  }

  &-header {
    color: $color-primary;
    padding: 15px;
    border-radius: 20px;
    width: 100%;
    align-items: center;
    font-size: $font-24;
    margin-top: 5px;
    h4 {
      word-wrap: anywhere;
    }
  }

  &-locations {
    padding: 40px 0 0 0;

    & .no-content {
      text-align: center;
    }
  }

  &-cards {
    @include flex-around-auto;
    padding: 10px 0;
    flex-wrap: wrap;

    & > div,
    & > a {
      width: 320px;
      min-height: 336px;
      margin-bottom: 20px;
    }
  }

  &-card {
    @include basic-card-container;
    @include flex-column-between-auto;
    padding: 13px;
    width: 320px;

    &-header {
      @include flex-between-center;
      background: $color-neutral-light;
      border-radius: 15px;
      padding: 14px;
    }

    &-title {
      @include text-ellipsis;
      font-size: $font-14;
      font-weight: 700;
      color: $color-primary;
    }

    &-price {
      font-size: $font-12;
      color: $color-primary;
      font-weight: 700;
    }

    &-percent {
      &-plus {
        margin-right: 12px;
        font-size: $font-24;
        font-weight: 700;
        color: $color-ui-notif;
      }
      &-less {
        margin-right: 12px;
        font-size: $font-24;
        font-weight: 700;
        color: $color-comparative-green;
      }
    }

    &-sub {
      font-size: $font-10;
      color: $color-neutral-dark;
    }

    &-content {
      margin-left: 10px;
    }

    &-city {
      @include flex-auto-center;
      @include text-ellipsis;
      font-weight: 700;
      font-size: $font-14;
      color: $color-primary;

      & > span {
        margin-left: 7px;
      }
    }

    &-surfaces {
      @include text-ellipsis;
      font-size: $font-14;
      color: $color-neutral-darker;
      margin-left: 22px;
    }

    &-comparison {
      @include flex-between-center;
      margin: 24px 0 0 0;

      &-elt {
        border: 1px dashed $color-neutral-dark;
        border-radius: 10px;
        padding: 10px 19px;

        & > span {
          display: block;
          text-align: center;
        }
      }

      &-label {
        font-size: $font-12;
        font-weight: 700;
        color: $color-neutral-darker;
      }

      &-minus,
      &-plus {
        font-weight: 700;
        font-size: $font-18;
      }

      &-minus {
        color: $color-comparative-green;
      }

      &-plus {
        color: $color-ui-notif;
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;

      & > button {
        margin-left: 9px;
      }
    }
  }
}
