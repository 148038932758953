@import '../../../../scss/main';

.team-card {
  @include basic-card-container;
  width: 250px;

  &-admin {
    @include flex-around-center;

    &-state {
      font-size: 12px;
    }
  }

  & > span {
    display: inline-block;
    margin: 10px;
  }

  &-people {
    width: 100%;
    padding: 15px 15px 30px 15px;
  }

  &-name {
    font-size: $font-16;
    margin-bottom: 2px;
  }

  &-mail {
    @include text-ellipsis;
    display: block;
    font-size: $font-14;
    color: $color-neutral-dark;
  }

  &-footer {
    @include flex-between-center;
    background: $color-neutral-light;
    padding: 11px;
    border-radius: 0 0 15px 15px;
    font-size: $font-14;
    color: $color-neutral-darker;
    width: 100%;

    label {
      margin: 0;
    }
  }
}
