@import '../../../../scss/main';

.user-pige {
  color: $color-neutral-darker;
  font-weight: 400;

  &-title {
    color: $color-primary;
    font-size: $font-16;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &-elt {
    border: 1px dashed $color-neutral-dark;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;

    @media screen and (max-width: 900px) {
      padding: 10px;
    }
  }

  &-list {
    &-title {
      color: $color-neutral-darker;
      font-size: $font-14;
      text-decoration: underline;
      font-weight: 700;
      margin-bottom: 15px;
    }

    &-item {
      color: $color-neutral-darker;
      font-weight: 400;
      margin: 0 0 10px 0;
    }
  }

  &-cities {
    max-height: 500px;
    overflow-y: auto;
    li {
      @include flex-auto-center;
      font-size: $font-14;
      color: $color-neutral-dark;
      font-weight: 700;
      margin-top: 10px;

      button {
        margin-right: 10px;
      }
    }
  }

  &-form-elt {
    & > div {
      margin-bottom: 20px;
    }
  }

  &-wrapper {
    @include flex-between-auto;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &-section {
    width: 46%;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
