@import '../../scss/main';

.my-folders-details {
  width: 100%;

  &-header {
    @include flex-between-auto;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &-right-header {
    @include flex-auto-center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin: 20px 0 0 0;

      & > button {
        margin-top: 10px;
      }
    }

    p {
      color: $color-primary;
      margin: 0 20px 0 10px;
      font-size: $font-12;
    }
  }

  &-actions {
    margin-top: 50px;
    float: right;

    @media screen and (max-width: 768px) {
      margin: 20px auto 0 auto;
      float: none;
      width: 250px;
    }

    & > div {
      min-width: 200px;
      margin-left: 20px;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin: 20px 0 0 0;
      }
    }
  }
}
