@import '../../scss/main';

.dashboard {
  &-section {
    margin-bottom: 40px;
    width: 100%;

    &-special-title {
      @include flex-between-center;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      align-content: flex-start;
      flex-wrap: wrap;
      margin-top: 15px;
      width: 100%;

      &-events {
        @include basic-card-container;
        margin-right: 20px;
        margin-top: 15px;
        min-height: 203px;

        & .no-content {
          font-size: 1.2em;
          margin-top: 0;
          line-height: 203px;
          text-align: center;
          color: $color-primary;
        }
      }

      @media screen and (max-width: 800px) {
        justify-content: center;
      }

      .action-card {
        width: 207px;
        height: 188px;
        margin: 0 0 10px 0;

        @media screen and (max-width: 376px) {
          width: 100%;
          margin-right: 0;
        }
      }

      & > table {
        max-width: 763px;
      }
    }
  }

  &-wrapper {
    display: flex;

    @media screen and (max-width: 1190px) {
      flex-wrap: wrap;
    }

    & > section:nth-child(1) {
      flex-grow: 1;
    }

    & > section:nth-child(2) {
      width: 330px;

      @media screen and (max-width: 376px) {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  &-info-card {
    @include box-shadow;
    @include flex-column-between-start;
    background-color: $color-primary;
    border-radius: 15px;
    width: 330px;
    color: #fff;
    padding: 40px 20px 30px 20px;
    height: 203px;
    background-repeat: no-repeat;
    background-position: bottom 0 right 20px;
    transition: box-shadow 0.4s;
    &:hover {
      @include box-hover;
    }

    &-nb {
      font-weight: 700;
      font-size: $font-48;
      display: block;
      line-height: 36px;
    }

    &-type {
      font-weight: 700;
      font-size: $font-14;
      line-height: 36px;
    }
  }

  & > section:last-of-type {
    margin-bottom: 0;
  }
}
