@import '../../../scss/main';

.button {
  @include font-btn;
  @include flex-center-center;
  font-size: $font-14;
  font-weight: 500;
  line-height: 24px;
  padding: 6px 16px;
  border-radius: 4px;
  border: none;

  &:hover {
    cursor: pointer;
  }

  &-text {
    svg {
      margin-left: 8px;
    }
  }

  &-svg {
    width: 36px;
    height: 36px;
    padding: 6px;
  }

  &-primary {
    @include box-shadow-btn;
    background: $color-primary;
    color: #fff;
    border: none;
  }

  &-disabled {
    cursor: default;

    &:hover {
      cursor: default;
    }
  }

  &-text {
    &-white {
      color: #fff;
    }
    &-violet {
      color: $color-primary;
    }
    &-blue {
      color: $color-secondary;
    }
    &-darker-grey {
      color: $color-neutral-darker;
    }
  }

  &-bg {
    &-violet {
      background: $color-primary;
    }
    &-white {
      background: #fff;
    }
    &-blue {
      background: $color-secondary;
    }
    &-red {
      background: $color-third-btn;
    }
    &-grey {
      background: $color-bg;
    }
    &-none {
      background: none;
    }
  }

  &-border {
    &-grey {
      border: 1px solid $color-row;
    }
    &-violet {
      border: 1px solid $color-primary;
    }
    &-white {
      border: 1px solid #fff;
    }
    &-darker-grey {
      border: 1px solid $color-neutral-darker;
    }
  }

  &-shadow {
    @include box-shadow-btn;
  }
}
