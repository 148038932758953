@import '../../scss/main';

.active-search-form {
  @include flex-column;
  margin-top: 20px;

  & > div {
    margin: 0 0 10px 0;
  }

  &-group {
    @include flex-between-auto;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
    .radius-label {
      display: flex;
      align-items: center;
      font-size: $font-14;
      width: 44%;
    }

    .radius-select {
      width: 54%;
      > div {
        width: 100%;
      }
    }
    & > div {
      width: 49%;

      @media screen and (max-width: 1024px) {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  &-column {
    @include flex-column;
    margin: 0;

    & > div {
      margin: 0 0 10px 0;
    }

    > .postal-code-add-message {
      color: $color-ui-notif;
      font-size: $font-12;
      font-style: italic;
      margin-bottom: 5px;
    }

    & > fieldset {
      @media screen and (max-width: 1024px) {
        margin-bottom: 10px;
      }
    }
  }
  .seller-name-filter-group,
  .keywords-filter-group {
    @include flex-column;
    margin: 0;

    & > div {
      margin: 0 0 10px 0;
    }

    & > fieldset {
      @media screen and (max-width: 1024px) {
        margin-bottom: 20px;
      }
    }
  }
  &-checkbox {
    display: flex;
    flex-direction: column;
    color: $color-primary;
  }

  &-btn-group {
    @include flex-center-auto;

    & > button:first-of-type {
      margin-right: 18px;
    }
  }
}
