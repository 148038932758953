@import '../../scss/main';

.my-account {
  @include basic-card-container;
  padding: 35px;
  width: 100%;
  margin: 35px 0 0 0;

  @media screen and (max-width: 768px) {
    padding: 15px;
  }

  &-wrapper {
    width: 100%;
    min-width: 200px;
  }

  &-content {
    padding: 50px 60px 35px 60px;

    @media screen and (max-width: 1100px) {
      padding: 30px 0 0 0;
    }

    h2 {
      @include font-content;
      font-weight: 700;
      margin-bottom: 40px;
      color: $color-primary;
    }
  }
}
