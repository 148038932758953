/* COLORS */
$color-primary: #5c2670;
$color-primary-light: #824c96;
$color-secondary: #1cd4fd;
$color-ui-notif: #ed6565;
$color-third-btn: #ff505f;
$color-ui-notif-opacity: #ffa6a6;
$color-bg: #fbfbfb;
$color-info-bg: #2196f3;
$color-info-text: #0d3c61;
$color-dark-blue: #145f77;
$color-neutral-darker: #334660;
$color-neutral-dark: #879ebe;
$color-neutral-dark-opacity: rgba(135, 158, 190, 0.7);
$color-neutral-light: #f0f4f8;
$color-border: #cecece;
$color-like: #93e896;
$color-like-text: #2d6121;
$color-badge-new: #73cf77;
$color-like-opacity: #c6ffc8;
$color-disabled: #b6b6b6;
$color-row: #cecece;
$color-comparative-green: #73cf77;
$color-bg-whiteSmoke: #e5e5e5;

/* FONTS */
$font-base: 16px;
$font-10: 0.625rem;
$font-12: 0.75rem;
$font-14: 0.875rem;
$font-16: 1rem;
$font-18: 1.125rem;
$font-24: 1.5rem;
$font-28: 1.75rem;
$font-32: 2rem;
$font-48: 3rem;
