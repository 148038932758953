@import '../../scss/main';

.sign {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  min-height: 100vh;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    max-width: 660px;
    margin: 0 auto;
    padding: 100px 0;

    .actionTitle {
      height: 56px;
      order: 1;
      flex-grow: 1;
    }

    .actionButton {
      height: 56px;
      order: 2;
      width: 158px;
    }

    .signIn {
      width: 100%;
      height: 100%;
      order: 3;
    }

    @media screen and (max-width: 900px) {
      .actionTitle {
        width: 260px;
        order: 1;
      }
    }
    @media screen and (max-width: 800px) {
      .actionTitle {
        height: 110px;
        order: 1;
      }

      .actionButton {
        order: 3;
        width: 100%;
      }

      .signIn {
        order: 2;
      }

      padding: 25px;
    }
  }
}
