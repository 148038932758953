@import '../../scss/main';

.assistance {
  @include basic-card-container;
  width: 100%;
  margin-top: 40px;
  padding: 65px 60px;

  @media screen and (max-width: 960px) {
    padding: 20px;
  }

  &-wrapper {
    width: 100%;
  }

  &-elt {
    @include flex-between-center;
    margin-bottom: 45px;

    @media screen and (max-width: 860px) {
      flex-direction: column;
    }

    & > div:nth-of-type(1) {
      width: 58%;

      @media screen and (max-width: 860px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    & > div:nth-of-type(2) {
      width: 40%;

      @media screen and (max-width: 860px) {
        width: 100%;
      }
    }
  }

  &-video {
    height: 327px;
    border-radius: 37px;
    background: $color-border;

    &-title {
      font-weight: 500;
      @include font-btn;
      text-transform: uppercase;
      color: $color-neutral-darker;
      font-size: $font-16;
      margin-bottom: 15px;
    }

    &-description {
      color: $color-neutral-dark;
      font-size: $font-12;
      line-height: 2.2;
      font-weight: 400;
      @include font-content;
    }

    & div,
    iframe {
      border-radius: 37px;
      border: none;
    }
  }
}
