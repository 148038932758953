@import '../../../scss/main';

.modal {
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: -7px 8px 16px rgba(0, 0, 0, 0.15);
  width: 95%;
  max-width: 840px;
  min-height: 100%;
  z-index: 10;
  background: $color-bg;
  padding: 50px;

  @media screen and (max-width: 768px) {
    box-shadow: none;
    width: 100%;
    padding: 50px 20px;
  }

  &-close {
    border-radius: 50%;
    border: none;
    background: none;
    position: absolute;
    top: 35px;
    left: -25px;

    @media screen and (max-width: 768px) {
      left: auto;
      right: 0;
      top: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
