@import '../../../scss/main';

.user-password {
  &-form-elt {
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      margin: 0;
    }

    & > div {
      width: 40%;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    & > div:nth-child(1) {
      margin-right: 35px;
    }
  }

  &-btns {
    display: flex;

    & > button {
      margin: 0;
    }

    & > button:nth-child(2) {
      margin-left: 16px;
    }
  }
}
