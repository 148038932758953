@import '../../../../scss/main';

.add-team-member {
  max-width: 700px;
  margin: auto;

  &-title {
    font-size: $font-24;
    color: $color-primary;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 700;
  }

  &-subtitle {
    font-size: $font-16;
    color: $color-primary;
    margin-bottom: 30px;
    font-weight: 700;

    &:nth-of-type(2) {
      margin: 40px 0 10px 0;
    }
  }

  &-form {
    @include basic-card-container;
    @include flex-column;
    background: #fff;
    padding: 35px;

    & > div {
      margin-bottom: 25px;
    }

    & > label {
      margin-left: 15px;
    }
  }

  &-btn-group {
    @include flex-between-auto;
    margin-top: 40px;

    & > button {
      margin: 0;
    }

    & > button:nth-child(1) {
      width: 20%;
    }

    & > button:nth-child(2) {
      width: 76%;
    }
  }
}
