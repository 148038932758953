@import '../../../../scss/main';

.ad-details {
  &-header {
    @include flex-between-center;
    margin-bottom: 15px;

    &-info {
      color: $color-primary;
    }

    &-title {
      @include flex-auto-center;
      font-size: $font-24;

      & > span {
        margin-left: 22px;
      }
    }

    &-date {
      color: $color-neutral-dark;
      font-size: $font-12;
      font-weight: 400;
    }

    &-btns {
      display: flex;
      width: 100px;

      & > button:nth-child(1) {
        margin-right: 16px;
      }
    }
  }

  &-section-title {
    font-weight: 700;
    font-size: $font-18;
    color: $color-primary;
  }

  &-ad-price {
    & > button {
      width: 100%;
    }
  }

  &-graph {
    margin: 30px 0;

    &-elt {
      @include basic-card-container;
      padding: 10px;
      margin-top: 15px;
    }
  }

  &-search {
    margin: 20px 0;

    &-text {
      font-size: $font-12;
      text-align: center;
      color: $color-neutral-darker;
    }

    &-card {
      @include basic-card-container;
      margin: 20px 0;
      padding: 30px 45px;
      background-repeat: no-repeat;
      background-position: bottom -40px center;
      background-size: 100%;

      @media screen and (max-width: 768px) {
        padding: 20px;
      }

      &-txt {
        display: block;
        color: $color-primary;
        font-size: $font-14;
        margin-bottom: 10px;
      }

      button {
        margin-top: 20px;
      }
    }

    &-map {
      width: 100%;
      height: auto;
      margin-top: 35px;
    }
  }

  &-similar {
    margin: 20px 0;

    @media screen and (max-width: 768px) {
      margin: 20px 0;
    }

    &-wrapper {
      @include basic-card-container;
      padding: 22px 15px 0 15px;
      margin: 15px 0;
      width: 100%;

      @media screen and (max-width: 768px) {
        overflow-x: auto;
      }
    }

    &-table {
      border-collapse: collapse;
      width: 100%;

      th,
      td {
        font-size: $font-14;
        text-align: left;
        padding: 5px;
      }

      th {
        font-weight: 500;
        padding-bottom: 16px;
      }

      td {
        color: $color-primary;
        border-top: 1px solid $color-neutral-light;
        padding-bottom: 5px;

        & > img {
          object-fit: contain;
          width: 40px;
        }
      }

      &-pagination {
        @include flex-center-center;
        width: 100%;
        padding: 20px 0;
      }
    }
  }

  & .no-content {
    padding-top: 20px;
    font-size: 1.5em;
    text-align: center;
  }
}
