@import '../../scss/main';

.badge {
  @include font-btn;
  font-size: $font-12;
  font-weight: 500;
  border-radius: 64px;
  padding: 5px 9px 4px 9px;
  text-align: center;

  &-mini {
    padding: 4px 11px;
  }

  &-text {
    &-white {
      color: #fff;
    }
    &-dark-grey {
      color: $color-neutral-dark;
    }
    &-darker-grey {
      color: $color-neutral-darker;
    }
    &-violet {
      color: $color-primary;
    }
    &-dark-green {
      color: $color-like-text;
    }
    &-dark-blue {
      color: $color-dark-blue;
    }
  }

  &-bg {
    &-grey {
      background: $color-neutral-light;
    }
    &-violet {
      background: $color-primary;
    }
    &-red {
      background: $color-ui-notif;
    }
    &-blue {
      background: $color-secondary;
    }
    &-green {
      background: $color-like;
    }
  }
}
