@import '../../scss/main';

.my-folders-form-page {
  width: 100%;

  &-header {
    @include flex-between-center;

    @media screen and (max-width: 800px) {
      flex-direction: column;
    }

    &-elt {
      margin-top: 20px;
    }
  }

  &-span {
    color: $color-primary;
  }

  &-content {
    margin-top: 40px;
  }
}
