@import '../../scss/main';

.my-folders {
  width: 100%;

  &-title {
    @include flex-auto-center;

    & > svg {
      margin-right: 10px;
    }

    & > span {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &-row {
    &.my-folders-sub {
      padding-left: 50px;
    }
  }

  &-actions {
    @include flex-auto-center;

    & > button:nth-child(1) {
      margin-right: 11px;
    }
  }

  &-header {
    @include flex-between-center;

    @media screen and (max-width: 600px) {
      flex-direction: column;

      & > div:nth-child(1) {
        margin-bottom: 20px;
      }
    }
  }

  &-content {
    @include basic-card-container;
    margin-top: 40px;

    &-actions {
      @include flex-auto-center;
      padding: 30px 30px 15px 30px;

      p {
        @include font-content;
        color: $color-primary;
        font-weight: bold;
        margin-right: 20px;
      }

      & > div {
        width: 175px;
        margin: 0 10px;
      }
    }
    table {
      width: 100%;
      border-spacing: 0;
      padding: 0 30px;
    }

    &.default {
      color: $color-primary-light;
      text-align: left;

      td {
        color: inherit;
      }
    }

    td,
    th {
      padding: 12px 0;
      font-size: $font-14;
      text-align: left;
    }

    td {
      color: $color-neutral-darker;
      font-weight: 700;
      border-top: 1px solid $color-row;
    }
  }

  &-desktop {
    @media screen and (min-width: 768px) {
      display: table-cell;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &-mobile {
    @media screen and (min-width: 768px) {
      display: none;
    }
    @media screen and (max-width: 768px) {
      display: table-cell;
    }
  }
}

.folder-name-selected {
  color: #23d3f5;
  text-decoration: underline;
}
