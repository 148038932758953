@import '../../scss/main';

.active-search-result {
  width: 100%;

  &-header {
    @include flex-between-center;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    &-elt {
      @include flex-between-center;
      margin-bottom: 20px;

      @media screen and (max-width: 1024px) {
        &:nth-child(2) {
          flex-direction: column;
        }
      }

      & > button {
        margin-left: 20px;

        @media screen and (max-width: 1024px) {
          margin: 20px 0 0 0;
        }
      }
    }

    &-action {
      width: 180px;

      & > div {
        width: 100%;
      }

      p {
        margin-top: 5px;
        font-size: 0.85em;
        color: $color-primary;
        text-align: center;
      }
    }
  }

  &-ads {
    color: $color-primary;
    margin-right: 13px;

    & > span:nth-child(1) {
      display: block;
      font-size: $font-24;
      font-weight: 700;
    }

    & > span:nth-child(2) {
      font-size: $font-12;
      font-weight: 400;
    }
  }

  &-search {
    @include basic-card-container;
    display: flex;
    justify-content: space-between;
    margin: 40px 0 20px 0;
    padding: 5px 10px;
    color: $color-primary;

    @media screen and (max-width: 768px) {
      margin: 20px 0;
      flex-direction: column;
    }

    .font-weight-line {
      font-weight: 700;
    }
    &-line {
      font-size: $font-14;
      padding: 7px 0;
      width: 33%;
      min-width: 300px;

      & > span:nth-child(1) {
        font-weight: 700;
      }
    }
  }

  &-sort {
    float: right;
    margin-bottom: 23px;

    @media screen and (max-width: 768px) {
      @include flex-column;
      float: none;
    }

    & > div {
      &:first-child {
        width: 180px;
      }
      min-width: 135px;
      margin-left: 18px;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin: 0 0 20px 0;
      }
    }
  }
}
