@import '../../scss/main';

.active-search {
  &-header {
    @include flex-between-center;

    &-buttons {
      @include flex-center-center;

      button:first-child {
        margin-right: 5px;
      }
    }
    @media screen and (max-width: 600px) {
      flex-direction: column;

      & > div:nth-child(1) {
        margin-bottom: 20px;
      }
    }
  }

  &-cards {
    @include flex-around-auto;
    flex-wrap: wrap;
    padding: 40px 0 0 0;

    & > div,
    & > a {
      width: 100%;
      max-width: 320px;
      margin-bottom: 20px;
      min-height: 247px;
    }
  }
}
