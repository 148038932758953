@import '../../../scss/main';

.my-folders-form {
  @include flex-column;
  @include basic-card-container;
  padding: 23px;
  margin-top: 20px;

  &-sub {
    @include flex-column;
    padding: 23px;

    @media screen and (max-width: 800px) {
      padding: 0;
    }

    & > div {
      margin: 0 0 30px 0;
      width: 60%;

      @media screen and (max-width: 1000px) {
        width: 100%;
      }
    }
  }

  &-modal {
    padding: 23px;
    display: flex;
    flex-direction: column;

    & > div {
      margin: 0 0 30px 0;
      width: 100%;
    }
  }

  &-btn-group {
    display: flex;
    margin-top: 10px;

    & > button:first-of-type {
      margin-right: 13px;
    }
  }
}
