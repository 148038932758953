@import '../../../scss/main';

.subscription-summary {
  @include basic-card-container;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%);
  z-index: 10;
  width: 100%;
  max-width: 588px;
  padding: 35px;
  color: $color-primary;
  p > span {
    font-weight: 700;
  }
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  h4 {
    text-align: center;
    margin-bottom: 20px;
  }

  &-confirm-sentense {
    margin-top: 20px;
  }
  button {
    width: 30%;
    margin: 35px auto 0 auto;
  }
}
