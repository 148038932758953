@import '../../scss/main';

.nav {
  @include basic-card-container;
  @include flex-column-between-auto;
  padding: 25px;
  height: 100%;
  width: 207px;
  max-height: 900px;

  &-wrapper {
    @media screen and (min-width: 800px) {
      height: 100%;
      margin-right: 55px;
    }
  }

  @media screen and (max-width: 800px) {
    display: none;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: $color-neutral-dark;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0;
    max-height: 2000px;
    margin: 0;
  }

  &.nav-mobile-active {
    display: flex;

    @media screen and (max-width: 800px) {
      img {
        margin-left: 25px;
      }
    }
  }

  &-logo {
    width: 18px;
    height: 18px;
    margin-right: 15px;
  }

  li {
    @include flex-auto-center;
    margin-bottom: 20px;

    &.active-nav {
      a {
        color: $color-primary;

        path {
          fill: $color-primary;
        }
      }
    }

    a {
      @include flex-auto-center;
      padding: 10px 0;
      width: 100%;
      text-decoration: none;
      font-size: $font-12;
      font-weight: 700;
      color: $color-neutral-dark;

      @media screen and (max-width: 800px) {
        color: #fff;
        padding-left: 25px;
        font-size: $font-14;

        path {
          fill: #fff;
        }
      }

      &:hover {
        color: $color-primary;

        path {
          fill: $color-primary;
        }
      }
    }
  }

  &-picture {
    display: block;
    margin: 0 auto 14px auto;

    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  &-elt {
    margin-top: 175px;

    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  &-coryright {
    display: block;
    padding: 20px 0 0 0;
    margin: 0 auto;
    font-size: $font-10;
    color: $color-neutral-dark;
    font-weight: 400;
    text-align: center;

    & > a {
      color: $color-neutral-dark;
    }

    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  &-mobile {
    &-user {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-elt {
      @include flex-between-center;
      display: none;
      margin: 60px 0;

      @media screen and (max-width: 800px) {
        display: flex;
      }
    }

    &-map {
      @include flex-center-center;
      font-size: $font-18;
      font-weight: 500;
      color: $color-primary;

      svg {
        margin-right: 11px;
        width: 16px;
      }
    }

    &-name {
      display: block;
      text-align: center;
      color: $color-primary;
      font-size: $font-14;
      font-weight: 700;
      margin-bottom: 3px;
    }

    &-deconnection {
      display: none;

      & > button {
        @include flex-center-center;
        background: none;
        border: none;
        color: #fff;
        padding: 10px 0 10px 25px;
        font-size: $font-14;
        font-weight: 700;
        display: none;

        @media screen and (max-width: 800px) {
          display: block;
        }

        &:hover {
          cursor: pointer;
          color: $color-primary;

          path {
            fill: $color-primary;
          }
        }
      }

      @media screen and (max-width: 800px) {
        display: flex;
      }
    }

    &-close {
      background: none;
      border: none;
      margin: 50px auto;
      transition-duration: 0.3s;
      transition-property: transform;
      width: 50px;
      height: 50px;
      display: none;

      &:hover {
        cursor: pointer;
        transform: rotate(90deg);
      }

      @media screen and (max-width: 800px) {
        display: block;
      }
    }
  }
}
