@import '../../../scss/main';

.procedure-event-modal {
  @include basic-card-container;
  position: fixed;
  left: 50%;
  top: 50vh;
  transform: translate(-50%, -50%);
  z-index: 11;
  width: 100%;
  max-width: 700px;
  padding: 35px;

  &-btn-container {
    & button {
      margin: auto;
      width: 50%;
    }
  }
}
