@import '../../scss/main';

.header {
  @include flex-between-center;
  @include box-shadow;
  width: 100%;
  padding: 20px 60px;
  background: #fff;

  @media screen and (max-width: 800px) {
    padding: 20px;
  }

  &-logo {
    @media screen and (max-width: 800px) {
      width: 112px;
    }
  }

  &-elts {
    @include flex-between-center;
  }

  &-title {
    @include flex-auto-center;
    font-size: $font-18;
    font-weight: 500;
    color: $color-primary;

    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  &-map {
    margin-right: 6px;
  }

  &-notif {
    margin: 0 38px;
    position: relative;
    cursor: pointer;

    &-on {
      background: $color-secondary;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 2px solid #fff;
      position: absolute;
      top: 3px;
      right: -3px;
    }
  }

  &-user {
    &-elt {
      margin-left: 11px;
      color: $color-neutral-dark;
      font-weight: 700;
      font-size: $font-14;
    }

    & > span:nth-child(2) {
      display: block;
    }

    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  &-profile {
    @include flex-between-center;

    @media screen and (max-width: 800px) {
      display: none;
    }

    &-open-menu {
      @include flex-between-center;
      justify-content: center;
      width: 20px;
      height: 30px;

      vertical-align: top;
      cursor: pointer;
      text-align: center;
    }
  }

  &-profile-logo {
    @include flex-center-center;
    width: 49px;
    height: 49px;
    border-radius: 50%;
    background: $color-neutral-dark;
    margin: 0 12px 0 25px;
    font-size: $font-24;
    font-weight: 700;
    color: $color-neutral-light;
  }

  &-mobile-icon {
    border: none;
    background: none;
    display: none;

    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 800px) {
      display: block;
    }
  }

  &-menu {
    z-index: 20;
    position: absolute;
    right: 15px;

    &-close {
      display: none;
    }

    &-open {
      @media screen and (max-width: 800px) {
        display: none;
      }

      display: block;
      float: bottom;
    }
  }
}
