@import '../../../../scss/main';

.adCardContainer {
  color: $color-primary;

  .noContent {
    text-align: center;
    color: $color-primary;
    font-size: 2em;
    margin-top: 40px;
  }

  .errorContent {
    text-align: center;
    font-size: 1.2em;
    margin-top: 40px;
  }

  .container {
    @include flex-around-auto;
    flex-wrap: wrap;
    clear: both;

    & > div {
      margin-bottom: 30px;
    }
  }
}
