@import '../../../scss/main';

.edit-folders {
  display: block;
  z-index: 5;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  &-modal {
    @include basic-card-container;
    position: relative;
    top: 100px;
    margin: auto;
    padding: 50px;
    width: 100%;
    max-width: 674px;
    z-index: 15;

    &-title {
      color: $color-primary;
      font-weight: 700;
      font-size: $font-18;
    }

    &-form {
      margin: 30px 0;

      & > div {
        margin: 0 0 35px 0;
      }

      div {
        display: block;
        width: 100%;
      }
    }

    &-btns {
      button {
        display: inline-block;

        &:nth-child(1) {
          margin: 0 13px 0 0;
        }
      }
    }
  }
}
