@import './variables';
@import './mixins';

* {
  @include font-content;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: auto;
  width: 100%;
}

html {
  font-size: $font-base;
}

ul {
  list-style: none;
}

.footer {
  z-index: 1;

  &-push {
    margin-top: 120px;
  }

  &-folders {
    @include footer-box;

    @media screen and (max-width: 768px) {
      @include flex-between-center;
    }

    &-btn {
      @include flex-center-center;

      & > button {
        margin-right: 10px;
      }
    }

    &-badges {
      & > span {
        display: block;

        &:nth-child(1) {
          margin-bottom: 10px;
        }
      }
    }
  }

  &-ad-creation {
    @include footer-box;
    z-index: 1;

    @media screen and (max-width: 768px) {
      @include flex-between-center;
    }

    &-print {
      @include flex-center-center;
      background: #fff;
      border: none;
      border-radius: 5px;
      width: 51px;
      height: 51px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &-market-result {
    @include footer-box;

    @media screen and (max-width: 768px) {
      @include flex-between-center;
    }

    &-print {
      @include flex-center-center;
      background: #fff;
      border: none;
      border-radius: 5px;
      width: 51px;
      height: 51px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &-account {
    @include footer-box;

    @media screen and (max-width: 768px) {
      @include flex-between-center;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.5);
}
