@import '../../scss/main';

.market-studies {
  &-header {
    @include flex-between-center;

    @media screen and (max-width: 600px) {
      flex-direction: column;

      & > div:nth-child(1) {
        margin-bottom: 20px;
      }
    }
  }

  &-cards {
    @include flex-around-auto;
    padding: 40px 0 0 0;
    flex-wrap: wrap;

    & > div,
    & > a {
      width: 320px;
      min-height: 247px;
      margin-bottom: 23px;
    }
  }

  &-card {
    @include basic-card-container;
    @include flex-column-between-auto;
    padding: 13px;
    width: 320px;

    &-header {
      background: $color-neutral-light;
      border-radius: 15px;
      padding: 14px;
    }

    &-title {
      @include text-ellipsis;
      font-size: $font-18;
      font-weight: 700;
      color: $color-primary;
    }

    &-subtitle {
      font-size: $font-10;
      color: $color-neutral-dark;
    }

    &-content {
      margin-left: 10px;
    }

    &-city {
      @include flex-auto-center;
      @include text-ellipsis;
      font-weight: 700;
      font-size: $font-14;
      color: $color-primary;

      & > span {
        margin-left: 7px;
      }
    }

    &-types {
      @include text-ellipsis;
      font-size: $font-14;
      color: $color-neutral-darker;
      margin-left: 25px;
    }

    &-date {
      font-size: $font-12;
      color: $color-neutral-dark;
      margin-left: 10px;
    }

    &-footer {
      @include flex-between-center;

      &-btns {
        @include flex-center-center;

        & > button {
          margin-left: 9px;
        }
      }
    }
  }
}
