@import '../../../../scss/main';

.edit-subscription {
  max-width: 700px;
  margin: auto;

  &-title {
    font-size: $font-24;
    color: $color-primary;
    margin-left: 20px;
    margin-bottom: 30px;
    font-weight: 700;
    @include font-content;
  }

  &-subtitle {
    font-size: $font-16;
    color: $color-primary;
    margin-bottom: 15px;
    font-weight: 700;
    @include font-content;
  }

  &-form {
    @include basic-card-container;
    background: #fff;
    padding: 35px;
  }

  &-textarea {
    margin-top: 30px;

    & > div {
      width: 100%;
    }
  }

  &-btn-group {
    @include flex-between-auto;
    margin-top: 40px;

    & > button {
      margin: 0;
    }

    & > button:nth-child(1) {
      width: 20%;
    }

    & > button:nth-child(2) {
      width: 76%;
    }
  }
}
