@import '../../scss/main';

.market-studies-result {
  &-wrapper {
    width: 100%;
    max-width: 1024px;
  }

  &-header {
    @include flex-between-center;

    &-titles {
      display: flex;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    &-sub {
      color: $color-primary;
      font-weight: 900;
      font-size: $font-24;
      margin: 3px 0 0 10px;

      @media screen and (max-width: 768px) {
        margin: 10px 0 0 0;
      }
    }

    &-btns {
      @include flex-auto-center;

      @media screen and (max-width: 768px) {
        display: none;
      }

      & > button {
        margin: 0 0 0 15px;
      }
    }
  }

  &-action-btn {
    width: 36px;
    height: 36px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 4.03846px rgba(0, 0, 0, 0.1);
    border: none;

    &:hover {
      cursor: pointer;
    }
  }

  &-summary {
    @include basic-card-container;
    padding: 20px 35px;
    margin: 50px 0 0 0;

    @media screen and (max-width: 768px) {
      @include flex-column;
      margin: 20px 0 0 0;
      padding: 20px;
    }

    &-section-title {
      color: $color-primary;
      font-weight: 700;
      font-size: $font-18;
      margin-bottom: 15px;
    }
  }

  &-sub-text {
    color: $color-neutral-dark;
    font-size: $font-16;
    margin-right: 25px;
  }

  &-prices {
    display: flex;
    margin-top: 30px;

    @include flex-column-between-center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      background: none;
      box-shadow: none;
    }

    &-type {
      display: flex;
      justify-content: flex-start;

      width: 100%;
      margin-bottom: 20px;
      border-radius: 15px;
      overflow: hidden;
    }

    &-section {
      margin-bottom: 70px;

      &:last-of-type {
        margin: 0;
      }
    }

    &-sub-title {
      display: block;
      font-weight: 700;
      margin-bottom: 25px;
    }

    &-line {
      @include flex-auto-center;
      font-size: $font-14;
      margin-bottom: 15px;

      & > em {
        width: 30%;
        font-style: normal;
      }

      & > span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        @media screen and (max-width: 768px) {
          left: auto;
          right: 0;
        }
      }
    }

    &-data {
      padding: 20px 35px;
      background: #fff;
      flex-grow: 1;

      @media screen and (max-width: 768px) {
        padding: 20px;
        border-radius: 15px;
        margin-bottom: 15px;
        @include basic-card-container;
      }

      h4 {
        color: $color-primary;
        font-size: $font-24;
        margin-bottom: 30px;
      }
    }
  }

  &-charts {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    background: $color-neutral-light;
    width: 318px;

    @media screen and (max-width: 768px) {
      padding: 20px;
      border-radius: 15px;
      margin-bottom: 15px;
      width: 100%;
      @include box-shadow;
    }

    &-elt {
      width: 100%;

      .graph-container {
        margin-top: 5px;
      }
    }

    h4 {
      color: $color-neutral-darker;
      margin-bottom: 10px;
    }

    form {
      & > div {
        width: 100%;
      }

      & > button {
        margin: 20px 0 0 0;
        float: right;
      }
    }
  }

  &-evolution {
    @include basic-card-container;
    padding: 25px 35px;
    background: #fff;
    margin-top: 40px;

    @media screen and (max-width: 800px) {
      @include basic-card-container;
      padding: 25px 20px;
      background: #fff;
      margin-top: 40px;
    }

    h4 {
      color: $color-primary;
      font-size: $font-18;
      margin-bottom: 15px;
    }

    &-elt {
      width: 100%;
    }
  }
}

.no-result {
  text-align: center;
  color: $color-primary;
  font-size: 2em;
  margin-top: 40px;
}
