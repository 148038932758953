@import '../../../scss/main';

.signChoiceButton {
  display: flex;
  flex-direction: column;
  color: $color-primary;

  button {
    width: 158px;
    margin: auto;
  }

  @media screen and (max-width: 800px) {
    margin-top: 20px;
    p {
      font-size: 0.75rem;
      text-align: center;
      line-height: 20px;
    }
  }
}
