.word-filter {
  display: flex;
  align-items: center;
  margin-bottom: 0;

  div {
    margin: 0 5px 0 0;
  }

  div:first-child {
    flex-grow: 1;
    margin-bottom: 1px;
  }
  .MuiRadio-root {
    padding-right: 0;
  }

  .MuiFormControlLabel-root {
    margin-right: 5px;
  }
}
