@import '../../scss/main';

.linkFromMenu {
  display: flex;
  align-items: center;
  color: $color-primary;
  padding: 10px 0;
  width: 100%;
  font-size: $font-12;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    font-weight: 700;
  }
  .logo {
    width: 28px;
    height: 18px;
    margin-right: 6px;
    position: relative;
    left: -5px;
  }
  @media screen and (max-width: 800px) {
    color: #fff;
    padding-left: 25px;
    font-size: $font-14;
    &:hover {
      color: $color-primary;
    }
  }
}

.urbaneaseButton {
  margin-left: 0 !important;
  @include box-shadow-btn;
  background-color: $color-primary;
  color: white;
  border: none;
  border-radius: 5px;
  align-self: center;
  cursor: pointer;

  &Content {
    @include flex-center-center;
    padding: 6px 10px;
    font-size: 1.1em;
    font-weight: 500;
    height: 38px;

    img {
      margin-left: 15px;
      width: 18px;
    }
  }
}

.noUrbaneaseAccountModal {
  color: $color-primary;
  margin: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    margin: 0;
  }
  img {
    margin: auto;

    @media screen and (max-width: 800px) {
      margin: auto;
      width: 250px;
    }
  }
  h1 {
    text-align: center;
    margin: 60px;

    @media screen and (max-width: 800px) {
      margin: 20px 0;
    }
  }
  .logo {
    display: flex;
    justify-content: center;
  }
  iframe {
    margin: 20px auto 40px auto;

    @media screen and (max-width: 800px) {
      margin: 20px;
    }
  }
  p {
    margin: 5px 20px;
    line-height: 24px;
  }
  button {
    @include box-shadow-btn;
    border-radius: 5px;
    align-self: center;
    cursor: pointer;
    margin-top: 20px;
    width: 50%;
    height: 60px;
    border: none;
    font-size: 1.2em;
    background-color: $color-primary;
    color: white;

    @media screen and (max-width: 800px) {
      width: 100%;
    }
    &:hover {
      box-shadow: 0 0 1px 1px rgb(0 0 0);
    }
    &:active {
      font-weight: 700;
    }
  }
}
