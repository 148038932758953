@import '../../../scss/main';

.presentation {
  position: relative;
  background: $color-primary;
  max-width: 480px;
  height: 100%;
  min-height: 100vh;
  padding: 100px 47px 15px 80px;

  @media screen and (max-width: 1100px) {
    max-width: 300px;
    padding: 100px 40px 15px 40px;
  }

  @media screen and (max-width: 800px) {
    max-width: 100%;
    min-height: 200px;
    max-height: 250px;
    padding: 40px 20px;
  }

  svg {
    @media screen and (max-width: 800px) {
      display: block;
      margin: auto;
    }
  }

  .title {
    color: #fff;
    font-weight: 700;
    font-size: $font-48;
    margin: 100px 0 200px 0;

    @media screen and (max-width: 1100px) {
      font-size: $font-32;
    }

    @media screen and (max-width: 800px) {
      font-size: $font-24;
      margin: 26px 0 0 0;
      text-align: center;
    }
  }

  .deco {
    @media screen and (max-width: 1100px) {
      width: 200px;
    }

    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  .copyright {
    color: $color-neutral-dark;
    font-size: $font-10;
    font-weight: 400;
    display: block;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;

    @media screen and (max-width: 800px) {
      display: none;
    }

    .link {
      color: $color-neutral-dark;
    }
  }
}
